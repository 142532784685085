import { Component, Input, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'app-copy-to-clipboard-button',
    templateUrl: './copy-to-clipboard-button.component.html',
    styleUrls: ['./copy-to-clipboard-button.component.scss']
})
export class CopyToClipboardButtonComponent {
    @Input() textToCopy: string;
    @Input() iconOnly = false;
    @ViewChild('tooltipCopy') tooltipCopy: MatTooltip;
    tooltipCopyTimerBeforeAutoHide: NodeJS.Timeout;

    onCopyBotAnswer(): void {
        void navigator.clipboard.writeText(this.textToCopy).then(() => {
            this.tooltipCopy.show();
        });
        clearTimeout(this.tooltipCopyTimerBeforeAutoHide);
        this.tooltipCopyTimerBeforeAutoHide = setTimeout(() => {
            this.tooltipCopy.hide();
        }, 2000);
    }
}
