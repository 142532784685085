import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PairKeyLabelI } from 'src/app/models/filter';

@Component({
    selector: 'app-migrated-collapsible-checkbox-tree-dropdown',
    templateUrl: './migrated-collapsible-checkbox-tree-dropdown.component.html',
    styleUrls: ['./migrated-collapsible-checkbox-tree-dropdown.component.scss']
})
export class MigratedCollapsibleCheckboxTreeDropdownComponent implements OnInit, OnChanges {
    @Input() keyLabelList: PairKeyLabelI[];
    @Input() initialKeySelection: string[];
    @Input() title: string;
    @Input() disabled: boolean;
    displayList: { isSelected: boolean; label: string; key: string }[];
    keyValueList: { isSelected: boolean; label: string; key: string }[];
    filterValue = '';
    numberOfSelected = 0;
    @Output() updateSelection: EventEmitter<string[]> = new EventEmitter<string[]>();

    handleOpenChange(isOpen: boolean): void {
        if (isOpen === false) {
            this.resetDisplay();
        }
    }

    onSubmitClick(): void {
        this.updateSelection.emit(this.generateSelectedList());
    }
    onRemoveClick(): void {
        this.keyValueList.forEach(keyValue => (keyValue.isSelected = false));
        this.displayList = this.keyValueList;
        this.numberOfSelected = 0;
        this.updateSelection.emit(this.generateSelectedList());
    }

    onFilterChange(newValue: string): void {
        this.displayList = this.keyValueList.filter(keyValue => keyValue.label.startsWith(newValue));
    }

    ngOnInit(): void {
        this.resetDisplay();
    }

    generateSelectedList(): string[] {
        return this.keyValueList.filter(keyValue => keyValue.isSelected).map(keyValue => keyValue.key);
    }

    generateKeyValueList(): { isSelected: boolean; label: string; key: string }[] {
        return this.keyLabelList.map(baseKeyLabel => {
            if (this.initialKeySelection && this.initialKeySelection.includes(baseKeyLabel.key)) {
                this.numberOfSelected += 1;
                return { label: baseKeyLabel.label, key: baseKeyLabel.key, isSelected: true };
            } else {
                return { label: baseKeyLabel.label, key: baseKeyLabel.key, isSelected: false };
            }
        });
    }

    resetDisplay(): void {
        this.numberOfSelected = 0;
        this.keyValueList = this.generateKeyValueList();
        this.displayList = this.keyValueList;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.initialKeySelection) {
            this.resetDisplay();
        }
    }
}
