import { AISearchPromptI } from '../models/aiSearch';
import { createStore } from '@ngneat/elf';
import { selectEntity, updateEntities, upsertEntities, withEntities } from '@ngneat/elf-entities';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

interface AiSearchPromptStateI {
    userUuid: string;
    aiSearchPrompts: AISearchPromptI[];
}

const store = createStore(
    { name: 'aiSearchPrompt' },
    withEntities<AiSearchPromptStateI, 'userUuid'>({ idKey: 'userUuid' }),
    withRequestsCache<`ai-search-prompts-${string}`>()
);
export const skipWhileAISearchPromptCached = createRequestsCacheOperator(store);
export const CACHE_KEY_AI_SEARCH_PROMPT = 'ai-search-prompts';

@Injectable({ providedIn: 'root' })
export class AISearchPromptRepository {
    getAiSearchPromptsByUserUuid$(userUuid: string): Observable<AISearchPromptI[]> {
        return store.pipe(selectEntity(userUuid, { pluck: 'aiSearchPrompts' }));
    }

    upsertAiSearchPromptsByUserUuid(userUuid: string, aiSearchPrompts: AISearchPromptI[]): void {
        store.update(
            updateRequestCache(`${CACHE_KEY_AI_SEARCH_PROMPT}-${userUuid}`),
            upsertEntities({ userUuid, aiSearchPrompts })
        );
    }

    addAiSearchPrompt(userUuid: string, aiSearchPrompt: AISearchPromptI): void {
        store.update(
            updateEntities(userUuid, entity => ({
                ...entity,
                aiSearchPrompts: [...entity.aiSearchPrompts, aiSearchPrompt]
            }))
        );
    }

    clearCacheOfCompany(companyUuid: string): void {
        store.update(updateRequestCache(`${CACHE_KEY_AI_SEARCH_PROMPT}-${companyUuid}`, { value: 'none' }));
    }
}
