import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-migrated-filter-date-range-selector',
    templateUrl: './migrated-filter-date-range-selector.component.html',
    styleUrls: ['./migrated-filter-date-range-selector.component.scss']
})
export class MigratedFilterDateRangeSelectorComponent implements OnChanges {
    @Input() dateFromModel: string;
    @Input() dateToModel: string;
    @Input() disabled: boolean;
    @Input() calendarPopupInBottom = false;
    isDateApplied = false;
    @Output() dateChangeEventEmitter = new EventEmitter<string[]>();

    apply(): void {
        this.isDateApplied = true;
        this.dateChangeEventEmitter.emit([this.dateFromModel, this.dateToModel]);
    }

    resetFilter(): void {
        this.dateToModel = '';
        this.dateFromModel = '';
        this.isDateApplied = false;
        this.dateChangeEventEmitter.emit([this.dateFromModel, this.dateToModel]);
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dateFromModel && !changes.dateFromModel.firstChange) {
            if (changes.dateFromModel.currentValue === '') {
                this.resetFilter();
            }
        }
        if (changes.dateToModel) {
            if (changes.dateToModel.currentValue === '' && !changes.dateToModel.firstChange) {
                this.resetFilter();
            }
        }
    }
}
